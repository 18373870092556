<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- {{ this.rowData }} -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button class="shadow" variant="gradient-danger" @click="$router.push({ name: 'outputkuruplun' })">
            กลับ
          </b-button>

          <b-button class="ml-1" @click="exportpdf"> ใบเบิกทรัพย์สิน </b-button>

          <div class="title head">
            <p class="text-center h3">รายการเบิกทรัพย์สิน</p>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน :</div>
            <div class="ml-1 h4">{{ this.rowData.equipment_code }}</div>
            <div class="ml-1 h4 text-primary" text="primary">ลงวันที่ :</div>
            <div class="ml-1 h4">
              {{
                `${parseInt(this.rowData.need_date.split("-")[2])} ${
                  this.month[parseInt(this.rowData.need_date.split("-")[1])]
                }${Number(this.rowData.need_date.split("-")[0]) + 543}`
              }}
            </div>

            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ: :</div>
            <div class="ml-1 h4">{{ this.rowData.budget_year }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">กลุ่มงาน :</div>
            <div class="ml-1 h4">
              {{ this.rowData.work_gname }}
            </div>
            <!-- <div class="ml-1 h4 text-primary">ฝ่ายงาน:</div>
            <div class="ml-1 h4">ฝ่ายคลังข้อสอบ</div> -->
            <div class="ml-1 h4 text-primary">ผู้เบิก/ผู้บังคับบัญชา:</div>
            <div class="ml-1 h4">{{ this.rowData.req_name }}</div>
          </div>
          <hr />

          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </b-dropdown-item>
                    <!-- <b-dropdown-item @click="exportpdf">
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>PDF</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>
                <span v-else-if="props.column.field === 'จำนวน'">
                  <div class="text-right">{{ props.row.จำนวน }}</div>
                </span>
                <!-- Column: Action
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon icon="GridIcon" class="mr-50" />
                      <span>QRCode</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span> -->

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3', '5', '10']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ props.total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import _ from "lodash";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      show: false,
      total: "",
      pageLength: 3,
      dir: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคาต่อหน่วย + vat 7%",
          field: "unitprice_vat",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        // {
        //   label: "จำนวน",
        //   field: "จำนวน",
        // },

        // {
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      user_fullname: "",
      status_user: "",
      date_1: "",
      date_2: "",
      date_3: "",
      supervisor: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/pick-up-equipment" }) : true, this.getdata();
    this.a_position();
    if (this.rowData.date_1 || this.rowData.date_2 || this.rowData.date_3) {
      console.log(this.rowData);
      const date1 = this.rowData.date_1;
      this.date_1 = `${parseInt(date1.split("-")[2])} ${this.month[parseInt(date1.split("-")[1])]} ${
        Number(date1.split("-")[0]) + 543
      }`;
      this.date_2 = `${parseInt(this.rowData.date_2.split("-")[2])} ${
        this.month[parseInt(this.rowData.date_2.split("-")[1])]
      } ${Number(this.rowData.date_2.split("-")[0]) + 543}`;
      this.date_3 = `${parseInt(this.rowData.date_3.split("-")[2])} ${
        this.month[parseInt(this.rowData.date_3.split("-")[1])]
      } ${Number(this.rowData.date_3.split("-")[0]) + 543}`;
    }
    const { dep_id, usertype, username, fullname } = await JSON.parse(localStorage.getItem("username_type"));
    this.status_user = usertype;
    // console.log(this.status_user);
    const url = `${API}registerWithdraw?fullname=${this.rowData.requistion_name}`;
    const { access_token } = await this.access_token();
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    console.log(res.data.message.data[0].dep_id);
    if (this.status_user == "ผู้ใช้ทั่วไป") {
      this.user_fullname = this.rowData.requistion_name;
      this.status_user = res.data.message.data[0].dep_id;
    } else {
      this.user_fullname = this.rowData.requistion_name;
      this.status_user = res.data.message.data[0].dep_id;
    }
  },
  methods: {
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `รายการเบิกทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "รายการเบิกทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `เลขทะเบียน: ${this.rowData.equipment_code}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `กลุ่มงาน: ${this.rowData.work_gname}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `วันที่ ${parseInt(this.rowData.need_date.split("-")[2])} ${
                          this.month[parseInt(this.rowData.need_date.split("-")[1])]
                        }${Number(this.rowData.need_date.split("-")[0]) + 543}`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: "หมายเลขทรัพย์สิน",
                            field: "e_number",
                          },
                          {
                            title: "SERIAL NUMBER",
                            field: "serial_number",
                          },
                          {
                            title: "รายการ",
                            field: "e_name",
                          },
                          {
                            title: "หน่วยนับ",
                            field: "unittype",
                          },
                          {
                            title: "ราคาต่อหน่วย",
                            field: "unitprice_ex",
                            type: "number",
                          },
                          {
                            title: "ราคาต่อหน่วย + vat 7%",
                            field: "unitprice_vat_ex",
                            type: "number",
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
        ],
      });
    },

    async getdata() {
      this.show = true;
      // console.log(this.rowData.equipment_code);
      const { access_token } = await this.access_token();
      const url = `${API}withdrawHistoryList?equipment_code=${this.rowData.equipment_code}`;

      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message);
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: Number(el.unitprice),
          unitprice_vat_ex: Number(el.unitprice_vat),
        };
      });
      this.total = res.data.message.total;
      this.show = false;
    },
    async a_position() {
      console.log(this.rowData.req_name);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${this.rowData.req_name}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      this.supervisor = res.data.message.data[0].dep_id;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 160, 10, 150],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "ใบเบิกทรัพย์สิน",
        },
        header: [
          {
            text: "ใบเบิกทรัพย์สิน",
            style: "header",
            margin: [0, 20, 0, 0],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },
          {
            text: `เลขที่ ${this.rowData.equipment_code.split("-")[0]}-${this.rowData.equipment_code.split("-")[1]}/${
              this.rowData.equipment_code.split("-")[2]
            }`,
            style: "header",
            alignment: "right",
            margin: [0, 20, 20, 0],
          },
          {
            text: `กลุ่มงานผู้ขอเบิก/ผู้ขอเบิก: ${this.rowData.work_gname}`,
            style: "header",
            alignment: "left",
            margin: [20, 20, 0, 0],
          },
          {
            text: `เพื่อให้เป็นไปตามระเบียบกระทรวงการคลังว่าด้วยการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐ พ.ศ 2560 ข้อ 204 และ 205 ข้าพเจ้าขอเบิกพัสดุตามรายการข้างล่างนี้ เพื่อใช่ในสำนักงาน ตามรายชื่อผู้รับผิดชอบ/ผู้รับมอบหมาย ดังนี้`,
            style: "header",
            alignment: "left",
            margin: [80, 20, 80, 10],
          },
        ],
        footer: {
          columns: [
            {
              text: `(ลงชื่อ).............................................................................................(ผู้ขอเบิกทรัพย์สิน)
              ( ${this.user_fullname})

               ตำแหน่ง: ${
                 this.status_user ? this.status_user : "..........................................................."
               }
              วันที่ :${
                this.rowData.need_date_th
                  ? this.rowData.need_date_th
                  : "......................................................."
              }


                    (ลงชื่อ).............................................................................................(ผู้สั่งจ่าย)
              ( ${
                this.rowData.staff_name2 == null
                  ? "........................................................................................"
                  : this.rowData.staff_name2
              } ) 

               ตำแหน่ง: ${
                 this.rowData.department
                   ? this.rowData.department
                   : "..........................................................."
               }
              วันที่ : ${
                this.rowData.date_2 ? this.date_2 : "......................................................."
              }     
              `,
              alignment: "center",
              style: "text",
              margin: [0, 0, 0, 15],
            },
            {
              text: `(ลงชื่อ).............................................................................................(ผู้เบิก/ผู้บังคับบัญชา)
              ( ${
                this.rowData.req_name == null
                  ? "........................................................................................"
                  : this.rowData.req_name
              } ) 

               ตำแหน่ง: ${
                 this.supervisor ? this.supervisor : "..........................................................."
               }
              วันที่ : ${
                this.rowData.need_date_th
                  ? this.rowData.need_date_th
                  : "......................................................."
              }   
              
              
               (ลงชื่อ).............................................................................................(ผู้จ่าย)
              ( ${
                this.rowData.staff_name1 == null
                  ? "........................................................................................"
                  : this.rowData.staff_name1
              } )  
              
              ตำแหน่ง: ${
                this.rowData.staff1_position
                  ? this.rowData.staff1_position
                  : "..........................................................."
              }
              วันที่ : ${this.rowData.date_1 ? this.date_1 : "......................................................."}
              `,
              alignment: "center",
              style: "text",
              margin: [0, 0, 0, 15],
            },
          ],
        },
        content: [
          // {
          //   columns: [
          //     {
          //       text: `เลขทะเบียน : ${this.rowData.equipment_code}`,
          //       style: 'title',
          //     },
          //     {
          //       text: `วันที่ :  ${parseInt(this.rowData.need_date.split('-')[2])} ${this.month[parseInt(this.rowData.need_date.split('-')[1])]}${
          //         Number(this.rowData.need_date.split('-')[0]) + 543
          //       }`,
          //       style: 'title',
          //     },

          //     {
          //       text: `ปีงบประมาณ : ${this.rowData.budget_year}`,
          //       style: 'title',
          //     },
          //   ],
          // },
          // {
          //   columns: [
          //     {
          //       text: `กลุ่มงาน : ${this.rowData.work_gname}`,
          //       style: 'title',
          //     },
          //     {
          //       text: `ผู้เบิก/ผู้บังคับบัญชา : ${this.rowData.req_name}`,
          //       style: 'title',
          //     },
          //   ],
          //   margin: [0, 0, 0, 20],
          // },

          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "*", "auto", "auto", "auto", "auto"],
              body: [
                [
                  { text: "ลำดับที่", style: "tableHeader" },
                  { text: "หมายเลขทรัพย์สิน", style: "tableHeader" },
                  { text: "SERIAL NUMBER", style: "tableHeader" },
                  { text: "รายการ", style: "tableHeader" },
                  { text: "หน่วยนับ", style: "tableHeader" },
                  { text: "ราคาต่อหน่วย", style: "tableHeader" },
                  { text: "ราคารวม VAT7%", style: "tableHeader" },
                  { text: "หมายเหตุ", style: "tableHeader" },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.e_number, style: "text_center" },
                    { text: res.serial_number, style: "text_center" },
                    { text: res.e_name, style: "text_left" },
                    { text: res.unittype, style: "text_center" },
                    {
                      text: res.unitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                      style: "text_right",
                    },
                    {
                      text: res.unitprice_vat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                      style: "text_right",
                    },
                    { text: "", style: "text_center" },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 9,
          },
          text_right: {
            alignment: "right",
            fontSize: 9,
          },
          text_left: {
            alignment: "left",
            fontSize: 9,
          },

          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`ใบเบิกทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
    },
    getfrom() {
      // console.log(id);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
